exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-classical-js": () => import("./../../../src/pages/classical.js" /* webpackChunkName: "component---src-pages-classical-js" */),
  "component---src-pages-covers-js": () => import("./../../../src/pages/covers.js" /* webpackChunkName: "component---src-pages-covers-js" */),
  "component---src-pages-dance-js": () => import("./../../../src/pages/dance.js" /* webpackChunkName: "component---src-pages-dance-js" */),
  "component---src-pages-electronic-js": () => import("./../../../src/pages/electronic.js" /* webpackChunkName: "component---src-pages-electronic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

